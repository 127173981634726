<template>
  <div class="thanks">
    <main>
      <div class="container">
        <div class="row text-center">
          <div class="col">
            <h1 class="jumbotron-heading">TEST COMPLETATO!</h1>
            <h5 class="lead text-muted">
              Complimenti! Hai completato il Test Motiva!
            </h5>
          </div>
        </div>

        <div class="row pt-5 text-center">
          <div class="col">
            <a href="https://www.habacus.com/it" target="_blank">
              <img
                src="../assets/logo_nuovo.png"
                style="max-width: 400px; width: 100%"
                alt="habacus_logo"
              />
            </a>
          </div>
        </div>
      </div>
    </main>
  </div>
  <div class="foot">
    <footer class="">
      <div class="container">
        <div class="row">
          <div class="col logoBianco">
            <img src="../assets/logo_bianco.png" alt="logo habacus bianco" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            Habacus è una data-driven company ad impatto sociale che sostiene la
            formazione post-diploma; un integratore di sistema che mette in rete
            studenti, enti formativi, finanza privata e istituzioni pubbliche.
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-md-12">
            <div class="contact">
              Vuoi ricevere maggiori informazioni sui nostri servizi? <br />
              Scrivici a
              <a href="mailto:info@habacus.com" style="color: #fff"
                >info@habacus.com</a
              >
            </div>
            <li class="icon-social">
              <a
                href="https://www.facebook.com/habacus.srl/"
                target="_blank"
                rel="noopener noreferrer"
                ><svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 320 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                  ></path></svg
              ></a>
            </li>
            <li class="icon-social">
              <a
                href="https://www.instagram.com/habacus_ita/"
                target="_blank"
                rel="noopener noreferrer"
                ><svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 448 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                  ></path></svg
              ></a>
            </li>
            <li class="icon-social">
              <a
                href="https://www.linkedin.com/company/h-abacus/"
                target="_blank"
                rel="noopener noreferrer"
                ><svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 448 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                  ></path></svg
              ></a>
            </li>
            <li class="icon-social">
              <a
                href="https://www.youtube.com/channel/UCmOWrnZDTuuvOYDrQdDZ2Cw"
                target="_blank"
                rel="noopener noreferrer"
                ><svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 576 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                  ></path></svg
              ></a>
            </li>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col">
            <span class="note">
              Habacus&nbsp;srl, Sede legale: Via Filippo Sassetti, 32, 20124
              Milano (MI), Italia <br />
              C.F. e numero di iscrizione presso il Registro delle Imprese di
              Milano Monza Brianza Lodi 04906910262 <br />
              Habacus srl &copy; 2022
            </span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import { CONFIG, URL_END_COURSE, URL_GET_TOKEN } from "../utils/variables";

export default {
  name: "Thanks",

  data: function () {
    return {
      isButtonActive: false,
      tag: "POSTE_ITALIANE",
      uuid: null,
    };
  },

  methods: {
    hideButton() {
      document.getElementById("buttonEnd").style.visibility = "hidden";
    },
  },

  mounted() {

    const params = new URLSearchParams(window.location.search);
    const self=this;
    self.uuid = params.get("uuid");
    axios
      .get(
        URL_GET_TOKEN
      )
      .then(
        (response) => {
          const hppHeader = {
            hpp: response.data.token
          };

          CONFIG.headers = Object.assign(CONFIG.headers, hppHeader)


          this.hideButton();

          if (this.uuid != null) {
            axios
              .get(
                URL_END_COURSE,
                Object.assign(Object.assign(CONFIG, {hpp:response.data.token}),{
                params: {
                  tag: this.tag,
                  uuid: this.uuid,
                }
              }))
              .then(() => {
                store.state.authorized = true;
                this.$router.push({
                  name: "Thanks",
                  query: { uuid: this.uuid },
                });

              });
          }

        })

  },
};
</script>

